import { storyblokEditable } from '@storyblok/react/rsc'
import { clsx } from 'clsx'
import { type CSSProperties, type FC } from 'react'

import { type StoryblokComponentProps } from '../../model'
import { type StoryblokGridType } from '../../model/storyblokTypes.generated'
import { useCmsGapStyles } from '../../styles'
import { getNonEmptyStyleStringValue } from '../../styles/getNonEmptyStyleStringValue'
import { StoryblokComponentsRenderer } from '../StoryblokComponentsRenderer'

import styles from './StoryblokGrid.module.css'

export const StoryblokGrid: FC<StoryblokComponentProps<StoryblokGridType>> = ({
  blok,
}) => {
  const { content, desktopColumns, mobileColumns, tabletColumns } = blok

  const { gapClassName, gapStyle } = useCmsGapStyles(blok)

  return (
    <div
      {...storyblokEditable(blok)}
      className={clsx(styles.grid, gapClassName)}
      style={
        {
          '--grid-columns-desktop': getNonEmptyStyleStringValue(desktopColumns),
          '--grid-columns-mobile': getNonEmptyStyleStringValue(mobileColumns),
          '--grid-columns-tablet': getNonEmptyStyleStringValue(tabletColumns),
          ...gapStyle,
        } as CSSProperties
      }
    >
      <StoryblokComponentsRenderer bloks={content} />
    </div>
  )
}
