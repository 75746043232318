import { storyblokEditable } from '@storyblok/react/rsc'
import { type CSSProperties, type FC } from 'react'

import { type StoryblokComponentProps } from '../../model'
import { type StoryblokGridCellType } from '../../model/storyblokTypes.generated'
import { StoryblokComponentsRenderer } from '../StoryblokComponentsRenderer'

import styles from './StoryblokGridCell.module.css'

export const StoryblokGridCell: FC<
  StoryblokComponentProps<StoryblokGridCellType>
> = ({ blok }) => {
  const { content, desktopColumnSpan, mobileColumnSpan, tabletColumnSpan } =
    blok

  return (
    <div
      {...storyblokEditable(blok)}
      className={styles['grid-cell']}
      style={
        {
          '--grid-column-span-desktop': desktopColumnSpan,
          '--grid-column-span-mobile': mobileColumnSpan,
          '--grid-column-span-tablet': tabletColumnSpan,
        } as CSSProperties
      }
    >
      <StoryblokComponentsRenderer bloks={content} />
    </div>
  )
}
