import { type CSSProperties } from 'react'

import { getNonEmptyStyleStringValue } from './getNonEmptyStyleStringValue'

import styles from './useCmsGapStyles.module.css'

/**
 * Generate styles for CSS `gap` property https://developer.mozilla.org/en-US/docs/Web/CSS/gap.
 */
export const useCmsGapStyles = ({
  desktopColumnGap,
  desktopRowGap,
  mobileColumnGap,
  mobileRowGap,
  tabletColumnGap,
  tabletRowGap,
}: {
  desktopColumnGap?: string | number
  desktopRowGap?: string | number
  mobileColumnGap?: string | number
  mobileRowGap?: string | number
  tabletColumnGap?: string | number
  tabletRowGap?: string | number
}): {
  gapClassName: string
  gapStyle: CSSProperties
} => ({
  gapClassName: styles['gap-styles'],
  gapStyle: {
    '--cms-styles-column-gap-desktop':
      getNonEmptyStyleStringValue(desktopColumnGap),
    '--cms-styles-column-gap-mobile':
      getNonEmptyStyleStringValue(mobileColumnGap),
    '--cms-styles-column-gap-tablet':
      getNonEmptyStyleStringValue(tabletColumnGap),
    '--cms-styles-row-gap-desktop': getNonEmptyStyleStringValue(desktopRowGap),
    '--cms-styles-row-gap-mobile': getNonEmptyStyleStringValue(mobileRowGap),
    '--cms-styles-row-gap-tablet': getNonEmptyStyleStringValue(tabletRowGap),
  } as CSSProperties,
})
